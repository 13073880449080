import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import NewsPostPage from '../components/NewsPostPage'

export default function NewsPost({ data }) {
  const post = data.allWpPost.nodes[0];

  return (
    <Layout>
      <NewsPostPage post={post} htmlTitle={data.site.siteMetadata.title} />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        excerpt
        content
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
