import React from 'react';
import { Helmet } from 'react-helmet';
import { parse } from 'node-html-parser';
import { navigate } from 'gatsby';

import Button from '../common/Button';

import * as cls from './NewsPostPage.module.scss';

export default function PostPage({ post, htmlTitle }) {
  const content = parse(post.content);
  const handleClickBack = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title>{`${htmlTitle} - ${post.title}`}</title>
        <meta name='Description' content={post.excerpt} />
      </Helmet>
      <div className={cls.page}>
        <Button mode='back' onClick={handleClickBack}>Back</Button>
        <h1 className={cls.pageTitle}>{post.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
}
